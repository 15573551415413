import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from './components/Home.vue'
import Swag from './components/Swag.vue'
Vue.use(VueRouter);


const routes = [
    {path: '/', component: Home},
    {path: '/doc/:id', component: Swag},
]

export default new VueRouter({
    routes,
    mode: 'hash'
})