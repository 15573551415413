<template>
  <b-navbar fixed-top type="is-dark">
    <template #brand>
      <b-navbar-item tag="router-link" :to="{ path: '/' }">
        <img
            src="../assets/logo.svg"
        >
      </b-navbar-item>
      <b-navbar-item tag="router-link" :to="{ path: '/' }">
      Documentation
      </b-navbar-item>
      <b-navbar-item tag="div" class="navbar-h-divider"></b-navbar-item>
      <b-navbar-item tag="div">
        <b>Swaggers:</b>
      </b-navbar-item>
    </template>
    <template #start>
      <b-navbar-item tag="router-link" :to="{ path: '/doc/' + swg.yaml }" v-for="swg in swags" :key="swg.name">
        {{ swg.name }}
      </b-navbar-item>

    </template>
  </b-navbar>
</template>

<script>
import apis from '../apis'
export default {
  name: 'Menu',
  data: () => {
    return {
      "swags": apis.sort((a, b) => (a.name > b.name) ? 1 : -1)
    }
  }
}
</script>
<style>
.navbar-h-divider {
       border-left: 1px solid gray;
       padding-left: 8px;
       padding-right: 8px;
}
</style>
<!--<style lang="scss">-->
<!--$navbar-item-active-color: #0d5aa7;-->

<!--</style>-->
