const apis = [
    {
        "name": "Authorization",
        "yaml": "authorization-api"
    },
    {
        "name": "Bookings",
        "yaml": "bookings-public-api"
    },
    {
        "name": "Experiences",
        "yaml": "experiences-public-api"
    },
]

export default apis