import Vue from 'vue'

import Buefy from 'buefy'
import 'buefy/dist/buefy.css'

import SphinxXml from 'vue-sphinx-xml'
import VueHighlightJS from 'vue-highlightjs'
import 'highlight.js/styles/xcode.css'

import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false
Vue.use(Buefy)

Vue.use(SphinxXml, {store})
Vue.use(VueHighlightJS)

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
