Documentation<template>
  <div :id="'swagger-' + this.$route.params.id">
  </div>
</template>

<script>
import SwaggerUI from 'swagger-ui'
import 'swagger-ui/dist/swagger-ui.css';

export default {
  name: "Swag",
  methods: {
    gen_swg() {
      SwaggerUI({
        url: `./docs/${this.$route.params.id}.yaml`,
        dom_id: '#swagger-' + this.$route.params.id,
      });
    }
  },
  mounted() {
    this.gen_swg()
  },
  updated() {
    this.gen_swg()
  }

}
</script>

<style scoped>

</style>